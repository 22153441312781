<template>
  <div class="md-layout justify-content-center">
    <div class="md-layout-item md-size-25">
      <FormText
        v-model="form.college_logo"
        label="College Logo URL"
      />
      <FormText
        v-model="form.app_privacy_link"
        label="Privacy Policy URL"
      />
      <FormColor
        v-model="form.app_color_primary"
        label="Primary color"
      />
      <FormColor
        v-model="form.app_color_primary_contrast"
        label="Primary - Text color"
      />
      <FormColor
        v-model="form.app_color_secondary"
        label="Secondary color"
      />
      <FormColor
        v-model="form.app_color_secondary_contrast"
        label="Secondary - Text color"
      />
    </div>
    <div class="md-layout-item md-size-40">
      <div
        class="app-preview"
        :style="previewStyles"
      >
        <p class="caption">
          <small>App Preview</small>
        </p>

        <img
          :src="form.college_logo"
          alt=""
        >

        <div class="login-container">
          <h3>Login</h3>
          <FormText
            label="Email"
            value=" "
          />
          <FormText
            label="Password"
            value=" "
          />
          <button>Login</button>

          <p class="text-center">
            Don't have an account? <b>Register</b>
            <br>
            <b>Privacy Policy</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormColor from '@/components/Inputs/FormColor.vue';
import FormText from '@/components/Inputs/FormText.vue';

export default {
  components: {
    FormColor, FormText,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      college_logo: null,
      app_privacy_link: null,
      app_color_primary: '#03c4ed',
      app_color_primary_contrast: '#ffffff',
      app_color_secondary: '#30d9af',
      app_color_secondary_contrast: '#ffffff',
    },
  }),
  computed: {
    previewStyles() {
      return Object.keys(this.form).reduce((r, k) => {
        const colorName = k.slice(10).replace('_', '-');

        r[`--color-${colorName}`] = this.form[k];
        return r;
      }, {});
    },
  },
  mounted() {
    this.form = this.data;
  },
};
</script>
