<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      New Mailer Form
    </template>
    <template slot="body">
      <form-text
        v-model="form.mailer"
        icon="mail"
        label="Mail"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-success float-right"
        @click="saveItem"
      >
        <md-icon class="mr-2">
          save
        </md-icon>
        Create
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText,
} from '@/components/Inputs';

export default {
  components: {
    FormText,
  },
  props: {},
  data() {
    return {
      form: {
        mailer: null,
      },
    };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveItem() {
      this.request(`${this.$API.SCHOOL_SETTINGS}/mailer`, 'post', { email: this.form.mailer }, () => {
        this.fireSuccess('Email Created Succesfully');
        this.$emit('save');
        this.close();
      });
    },
  },
};
</script>
  <style></style>
