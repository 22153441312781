<template>
  <md-card>
    <md-card-header
      class="md-card-header-icon"
    >
      <div class="card-icon">
        <md-icon>school</md-icon>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <tabs
          plain
          :tab-name="[
            'General Settings',
            'App Settings',
            'Stripe Settings',
            'Mailer Settings',
            'Sign Request Settings',
            'Attendence Settings',
          ]"
          color-button="success"
        >
          <template slot="tab-pane-1">
            <div class="md-layout-item md-full">
              <FormText
                v-model="form.campus_name"
                label="Campus Name"
              />
              <FormSelect
                v-model="form.lang"
                :options="langOptions"
                label="Language"
              />
              <FormSelect
                v-model="form.date_format"
                :options="dateOptions"
                label="Date Format"
              />
              <FormSelect
                v-model="form.menu_active_color"
                :options="menuActiveColorsOptions"
                label="Menu Active Color"
              />
              <div class="color">
                <p class="md-body-1">
                  Menu Background Color
                </p>
                <input
                  v-model="form.menu_bg_color"
                  type="color"
                >
              </div>
              <div class="color">
                <p class="md-body-1">
                  Menu Text Color
                </p>
                <input
                  v-model="form.menu_text_color"
                  type="color"
                >
              </div>
              <div
                class="color color-gradient"
                :style="publicProfileBgPreview"
              >
                <p class="md-body-1">
                  Public Profile Gradient Background
                </p>
                <div>
                  <input
                    v-model="form.public_profile_bg_1"
                    type="color"
                  >
                  <input
                    v-model="form.public_profile_bg_2"
                    type="color"
                  >
                </div>
              </div>
            </div>
          </template>
          <template slot="tab-pane-2">
            <div class="md-layout-item md-size-100">
              <AppSettings :data="form" />
            </div>
          </template>

          <template slot="tab-pane-3">
            <div class="md-layout-item">
              <FormText
                v-model="form.stripe_secret"
                label="Stripe Secret"
              />
              <FormText
                v-model="form.stripe_public"
                label="Stripe Public"
              />
            </div>
          </template>

          <template slot="tab-pane-4">
            <div class="md-layout-item">
              <MailerSettings />
            </div>
          </template>

          <template slot="tab-pane-5">
            <div class="md-layout-item">
              <FormText
                v-model="form.sign_request_token"
                label="Sign Request Token"
              />
              <FormText
                v-model="form.sign_request_api_base_url"
                label="Sign Request API BASE URL"
              />
              <FormText
                v-model="form.sign_request_email"
                label="Sign Request Email"
              />
            </div>
          </template>

          <template slot="tab-pane-6">
            <div class="md-layout-item md-size-100">
              <h5 class="font-weight-bold">
                Attendance Rules
              </h5>

              <md-switch
                v-model="form.attendance_rule_90_min"
                class="mb-0"
              >
                Attendance Rule 90 MIN
              </md-switch>
              <md-switch
                v-model="form.attendance_rule_full_day"
                class="mb-2"
              >
                Attendance Rule Full Day
              </md-switch>
            </div>
            <div
              class="md-layout-item md-size-100"
            >
              <hr>
              <h5 class="font-weight-bold">
                Attendance Warning automation
              </h5>

              <template
                v-if="warningSystemActive"
              >
                <div class="alert opacity-7 text-center">
                  <p>
                    This automation will send emails with the letter to students
                    with MAX. ATTENDANCE PERCENTAGE below the number indicated. <br>
                    If there is no template selected, no email will be sent. <br>
                    You can especify the status the programme will have after an
                    expulsion letter has been sent.
                  </p>

                  <p>
                    All the previous warning letter templates will be set as a normal
                    letter when sending this form.
                  </p>
                </div>

                <div class="md-layout mb-4 px-0">
                  <div class="md-layout-item md-size-25 pl-0">
                    <FormDate
                      v-model="form.warning_start_date"
                      label="Programmes starting from"
                    />
                  </div>
                  <div class="md-layout-item md-size-25">
                    <CoursesSelect
                      v-model="form.warning_courses"
                      label="Courses affected"
                      multiple
                    />
                  </div>
                  <div class="md-layout-item md-size-25">
                    <AuthoritiesSelect
                      v-model="form.default_authority_id"
                      label="Default Authority"
                    />
                  </div>
                  <div class="md-layout-item">
                    <md-button
                      class="md-primary float-right"
                      @click="addWarning"
                    >
                      <md-icon>add</md-icon>
                      New warning
                    </md-button>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tr>
                      <th />
                      <th>Letter Template</th>
                      <th>Max. Attendance Percentage Below</th>
                      <th>Is expulsion</th>
                      <th>Programme Status after expulsion</th>
                      <th />
                    </tr>
                    <tr
                      v-for="(w, wi) of form.warnings"
                      :key="wi"
                    >
                      <td>{{ toOrdinal(wi+1) }}</td>
                      <td>
                        <LetterTemplatesSelect
                          v-model="form.warnings[wi].letter_template_id"
                          null-option="None"
                          @change="onTemplateChange($event, wi)"
                        />
                      </td>
                      <td>
                        <FormNumber
                          v-model="form.warnings[wi].max_attendance_rate"
                          icon="percent"
                          label="Max. Attendance Percentage below"
                        />
                      </td>
                      <td>
                        <md-switch v-model="w.is_expulsion">
                          Is expulsion
                        </md-switch>
                      </td>
                      <td>
                        <ProgrammeStatusesSelect
                          v-if="w.is_expulsion"
                          v-model="form.warnings[wi].expulsion_status"
                        />
                      </td>
                      <td>
                        <md-button
                          class="md-danger md-just-icon float-right"
                          @click="removeWarning(wi)"
                        >
                          <md-icon>delete</md-icon>
                          <md-tooltip>Remove warning</md-tooltip>
                        </md-button>
                      </td>
                    </tr>
                  </table>
                </div>
              </template>

              <div
                v-else
                class="alert opacity-7 text-center"
              >
                This automation is currently inactive. You can activate it from
                Admin / System Parameters
              </div>
            </div>
          </template>
        </tabs>
      </div>

      <md-button
        class="md-raised md-success mt-4 md-block"
        @click="sendForm"
      >
        Update College Settings
      </md-button>
    </md-card-content>
  </md-card>
</template>

<script>
import {
  FormText, FormSelect, FormNumber, FormDate,
} from '@/components/Inputs';
import {
  LetterTemplatesSelect, ProgrammeStatusesSelect, CoursesSelect, AuthoritiesSelect,
} from '@/components/Inputs/selects';
import { Tabs } from '@/components';
import MailerSettings from './MailerSettings.vue';
import AppSettings from './AppSettings.vue';

export default {
  components: {
    FormText,
    FormSelect,
    FormNumber,
    FormDate,
    Tabs,
    MailerSettings,
    LetterTemplatesSelect,
    ProgrammeStatusesSelect,
    CoursesSelect,
    AppSettings,
    AuthoritiesSelect,
  },
  data: () => ({
    menuActiveColorsOptions: [
      'purple',
      'azure',
      'green',
      'orange',
      'danger',
      'rose',
    ].map((option) => ({ id: option, name: option })),
    langOptions: [
      'es',
      'en',
    ].map((option) => ({ id: option, name: option })),
    dateOptions: [
      'DD-MM-YYYY',
      'YYYY-MM-DD',
    ].map((option) => ({ id: option, name: option })),
    form: {
      lang: 'en',
      stripe_secret: null,
      stripe_public: null,
      campus_name: null,
      sign_request_token: null,
      sign_request_api_base_url: null,
      sign_request_email: null,
      attendance_rule_90_min: true,
      attendance_rule_full_day: true,
      menu_bg_color: null,
      menu_text_color: null,
      menu_active_color: '',
      date_format: 'DD-MM-YYYY',
      public_profile_bg_1: '#30d9af',
      public_profile_bg_2: '#03c4ed',
      college_logo: null,
      warnings: [],
      warning_start_date: null,
      warning_courses: [],
      default_authority_id: null,
    },
  }),
  computed: {
    publicProfileBgPreview() {
      return `
      --color-1: ${this.form.public_profile_bg_1};
      --color-2: ${this.form.public_profile_bg_2};
      `;
    },
  },
  mounted() {
    this.$store.dispatch('school_settings/getSchoolSettings', {
      cb: () => {
        this.form = JSON.parse(JSON.stringify(this.$schoolSettings));
      },
      force: true,
    });
  },
  methods: {
    toOrdinal(n) {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    validate() {
      let valid = true;
      const { warnings, default_authority_id } = this.form;

      if (!default_authority_id) {
        this.fireError('Please select a Default authority');
        valid = false;
      }

      const expulsions = warnings.filter((a) => a.is_expulsion);

      if (expulsions.length > 1) {
        this.fireError('There is more than 1 expulsion warning');
        valid = false;
      } else if (expulsions.length === 1 && !warnings[warnings.length - 1].is_expulsion) {
        this.fireError('The last warning must be the expulsion letter');
        valid = false;
      } else if (expulsions.length === 1 && !expulsions[0].expulsion_status) {
        this.fireError('Please select a valid status for the expulsion letter');
        valid = false;
      }

      if (warnings.some((x) => x.letter_template_id === 'null' || !x.letter_template_id)) {
        this.fireError('Please select a valid letter template for all the warnings');
        valid = false;
      }

      return valid;
    },
    sendForm() {
      if (!this.validate()) return;

      this.$store.dispatch('school_settings/updateSchoolSettings', {
        form: this.form,
        cb: () => {
          this.fireSuccess('School Settings Updated succesfully');
        },
      });
    },
    addWarning() {
      this.form.warnings.push({
        letter_template_id: null,
        max_attendance_rate: null,
        expulsion_status: null,
        is_expulsion: false,
      });
    },
    removeWarning(index) {
      this.form.warnings.splice(index, 1);
    },
    onTemplateChange(value, index) {
      if (value.id === 'null') {
        this.form.warnings[index].max_attendance_rate = null;
        this.form.warnings[index].expulsion_status = null;
        this.form.warnings[index].is_expulsion = false;
      }
    },
  },
};
</script>

<style scoped>
  .md-body-1 {
    color: rgb(0 0 0 / 35%);
  }

  .color {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .color input {
    appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .color + .color {
    margin-top: 6px;
  }

  .color.color-gradient > div {
    position: relative;
  }

  .color.color-gradient > div:after {
    content: '';
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, var(--color-1) 0%, var(--color-2) 100%);
  }

  ::v-deep .tab-content > * {
    width: 100%;
  }
</style>
