<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-just-icon md-round md-primary pull-right"
          @click="onCreate"
        >
          <md-icon>add</md-icon>
          <md-tooltip>Add mailer</md-tooltip>
        </md-button>

        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :editable="true"
          :search="false"
          :options="{
            tableClass:'table'
          }"
          @itemChecked="onItemChecked"
          @changed="getItems"
          @onDelete="onDelete"
        />
      </div>
    </div>

    <NewMailerForm
      v-if="modalOpen"
      class="pt-5"
      @close="closeModal"
      @save="$refs.vtable.init"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import NewMailerForm from './MailerSettingsForm.vue';

export default {
  components: {
    VueTable,
    NewMailerForm,
  },
  props: [],
  data() {
    return {
      selectedItem: null,
      modalOpen: false,
      vTable: {
        headers: [
          {
            title: 'mailer',
            mask: 'mail',
            sortable: true,
          },
          {
            title: 'verified',
            sortable: true,
            callback: (item) => {
              if (item) {
                return 'YES';
              } return 'NO';
            },
          },
          {
            title: 'mailer_default_inbox',
            mask: 'default',
            checkeable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-danger',
            disabledParam: 'deletable',
            tooltip: 'Delete Source',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getItems(params) {
      this.request(this.$API.SCHOOL_SETTINGS + '/mailer'.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.editable = !!(x.verified && !x.mailer_default_inbox);
          x.deletable = !x.mailer_default_inbox;
          return x;
        });
      });
    },
    onCreate() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.selectedItem = null;
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Mailer',
        `Are you sure you want to delete this Email "${item.mailer}"?`,
      )
        .then(() => {
          this.request(`${this.$API.SCHOOL_SETTINGS}/mailer`, 'delete', { email: item.mailer }, () => {
            this.fireSuccess('Mailer deleted successfully.');
            // Reset Table after response
            this.$refs.vtable.init();
          });
        });
    },
    onItemChecked(item) {
      this.request(`${this.$API.SCHOOL_SETTINGS}/mailer`, 'put', { email: item.mailer }, () => {
        this.fireSuccess('Default mailer updated succesfully!');
        // Reset Table after response
        this.$refs.vtable.init();
      });
    },
  },
};
</script>

  <style scoped>
  </style>
